.sericulturalberhampore{
    background-color:rgb(239, 229, 216)
}


/* Dropdown Button */
.dropbtn {
    background-color: rgb(55, 55, 58);
    color: white;
    padding: 8px;
    font-size: 16px;
    border: none;
     border-radius: 14px;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: rgb(42, 38, 38) 64, 64;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {background-color: rgb(7, 7, 8);}






.dropbtn{
    color: gold;
}




.dropdown-menu li {
    position: relative;
    }
    .dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
    }
    .dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
    }
    .dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
    }






    .downloadbutton{
      background-color: rgb(28, 111, 137); /* Green */
      border: none;
      color: white;
      padding: 5px 16px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;
      border-radius: 6px;
    }

    
    .loginbutton{
      background-color: rgb(118, 3, 83); /* Green */
      border: none;
      color: white;
      padding: 5px 16px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;
      border-radius: 6px;
    }



    .button21 {
      transition-duration: 0.4s;
    }
    
    .button21:hover {
      background-color: #3dc996; /* Green */
      color: white;
      padding: 5px;
    }

    /* .button21{
      color: rgb(28, 28, 108);
    } */

    .button54{
      color:#4272f4
    }